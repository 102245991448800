import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Select from 'react-select';
import { exercisesAPI, workoutsAPI, workoutTemplatesAPI } from 'apiBank';

import StarRating from 'components/starRating/StarRating';

function WorkoutTemplateDetailsModal({ show, onClose, template, onSave, onCreateWorkout, onDelete }) {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [formData, setFormData] = useState({
    name: '',
    exercises: [],
    rating: 1, // Default rating
  });
  const [allExercises, setAllExercises] = useState([]);

  useEffect(() => {
    if (template) {
      setFormData({
        name: template.name || '',
        exercises: template.exercises.map((exercise) => ({
          label: exercise.name,
          value: exercise._id,
        })),
        rating: template.rating || 1, // Initialize with template rating or default
      });

      refreshExercises();
    }
  }, [template]);

  const refreshExercises = () => {
    exercisesAPI.getExercises().then((exercises) => {
      setAllExercises(exercises);
    });
  };

  const handleSelectChange = (selectedOptions) => {
    setFormData({
      ...formData,
      exercises: selectedOptions,
    });
  };

  const handleRatingChange = (newRating) => {
    setFormData({
      ...formData,
      rating: newRating, // Update rating in formData
    });
  };

  const handleSave = () => {
    const updatedTemplate = {
      ...template,
      name: formData.name,
      exercises: formData.exercises.map((exercise) => exercise.value),
      rating: formData.rating, // Include updated rating
    };

    onSave(updatedTemplate);
  };

  const generateUUID = () => {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
          const r = Math.random() * 16 | 0;
          const v = c === 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
      });
  }  
  
  const createWorkout = () => {
    if (window.confirm("Are you sure you want to create a workout from this template?")) {
      // Prompt for workout name
      const workoutName = window.prompt(
        "Enter a name for the workout:",
        `Workout - ${new Date().toLocaleDateString('en-US')}`
      );

      if (workoutName) {
        // Default to the generated name if none is provided
        const finalWorkoutName = workoutName && workoutName.trim() ? workoutName.trim() : `Workout - ${new Date().toLocaleDateString('en-US')}`;

        let exerciseObjects = template.exercises.map((exercise) => {
          return {
            ...exercise,
            exerciseId: exercise._id,
            sets: [
              {
                id: generateUUID(), // Generate a unique ID for the set
                weight: 0,
                reps: 0,
                bbClickCount: 0,
              },
            ],
          };
        });

        // Create the new workout object that will be inserted
        const workoutData = {
          user_id: user._id,
          name: finalWorkoutName,
          date: new Date().toISOString(),
          exercises: exerciseObjects,
          active: true,
          notes: [],
          repCount: 0,
          setCount: 1,
        };

        workoutsAPI.insertWorkout(workoutData).then((response) => {
          console.log('response', response);
          if (typeof onCreateWorkout === 'function') onCreateWorkout(response);
        });
      }
    }
  };

  const handleDelete = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete this workout template? This action cannot be undone."
    );
    
    if (!userConfirmed) {
      return; // If the user cancels, do nothing
    }

    workoutTemplatesAPI
      .deleteWorkoutTemplate(template._id)
      .then(() => {
        // alert("Workout template deleted successfully.");
        onDelete();
        onClose(); // Close the modal after successful deletion
      })
      .catch((error) => {
        console.error("Error deleting workout template:", error);
        alert("An error occurred while deleting the workout template.");
      });
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Workout Template</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {/* Template Name */}
          <Form.Group controlId="templateName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
          </Form.Group>

          {/* Exercises Multi-select */}
          <Form.Group controlId="formExercises" className="mt-3">
            <Form.Label>Exercises</Form.Label>
            <Select
              options={allExercises.map((exercise) => ({
                label: exercise.name,
                value: exercise._id,
              }))}
              isMulti
              value={formData.exercises}
              onChange={handleSelectChange}
              placeholder="Select exercises"
            />
          </Form.Group>
        </Form>
        {/* Star Rating Component */}
        <h2 style={{textAlign: 'center', margin: '1rem 0 0'}}><StarRating rating={formData.rating} onRatingChange={handleRatingChange} locked={false} /></h2>
      </Modal.Body>
      <Modal.Footer className="flex-column flex-md-row">
        <Button
          variant="secondary"
          className="w-100 mb-2 mb-md-0 me-md-2"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          variant="danger"
          className="w-100 mt-2"
          onClick={handleDelete}
        >
          Delete
        </Button>        
        <Button
          variant="success"
          className="w-100 mb-2 mb-md-0 me-md-2"
          onClick={createWorkout}
        >
          Create Workout
        </Button>
        <Button variant="primary" className="w-100" onClick={handleSave}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default WorkoutTemplateDetailsModal;
