import moment from 'moment';

export const getMinutes = (hours, minutes, seconds) => {
    return ((hours * 60) + (minutes + (seconds / 60))).toFixed(2)
}

export const makeId = function(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const cloneArray = function(arr) {
    const newArray = arr.map(a => ({...a}));

    return newArray;
}

export const findIndex = function(arr, prop, value) {
    const index = arr.findIndex(x => x[prop] === value);

    return index;
}

export const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ('' + str).replace(/\D/g, '');
  
  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  };

  return null
};

export const numberWithCommas = (x) => {
    if (!x) return 0;

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const dynamicSort = property => {
    let sortOrder = 1;

    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }

    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

// export const dynamicSort = property => {
//     let sortOrder = 1;

//     if(property[0] === "-") {
//         sortOrder = -1;
//         property = property.substr(1);
//     }

//     return function (a,b) {
//         /* next line works with strings and numbers, 
//          * and you may want to customize it to your needs
//          */
//         const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
//         return result * sortOrder;
//     }
// }

export const calcLoadFactor = (monthlyKwh, billingDemandKw, billingPeriod) => {
    const loadFactor = (parseFloat(monthlyKwh / (billingDemandKw * billingPeriod * 24)) * 100).toFixed(2);
    let range = '';
    let color = 'inherit';

    if (loadFactor >= 0 && loadFactor <= 39) {
        range = 'low';
        color = '#FF4136';
    } else if (loadFactor >= 40 && loadFactor <= 60) {
        range = 'medium';
        color = '#FFDC00';
    } else if (loadFactor >= 61) {
        range = 'high';
        color = '#2ECC40';
    }

    return {
        loadFactor: loadFactor,
        range: range,
        color: color,
    }
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const logFormData = formData => {
    // Display the key/value pairs
    for (var pair of formData.entries()) {
        console.log(pair[0]+ ', ' + pair[1]); 
    }
}

export const scrollTo = htmlIdentifier => {
    // var aTag = $("#" + id);
    // $('html,body').animate({scrollTop: aTag.offset().top},'slow');

    const element = document.getElementById(htmlIdentifier);
    element.scrollIntoView();
}

export const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random()
        * (max - min + 1)) + min;
};

export const random_items = (items, noOfObjects) => {
    // Use Math.random() to generate a random number between 0 and 1,
    // multiply it by the length of the array, and use Math.floor() to round down to the nearest integer

    if (parseInt(noOfObjects) > 1) {
        var result = [];
        for (var i = 0; i < noOfObjects; i++) {
            result.push(items[Math.floor(Math.random()*items.length)]);
        }
        return result;
    } else {
        return items[Math.floor(Math.random() * items.length)];
    }    
}

export const random_items_NEW = (items, noOfObjects) => {
    let arrayCopy = [...items];
    let newArray = [];
    for(let i = 0; i < noOfObjects; i++) {
        // Return if we've exhausted the amount of items in the array
        if (i+1 <= items.length) {
          let randNum = Math.floor(Math.random()*arrayCopy.length);
          let splicedItem = arrayCopy.splice(randNum, 1)[0]
          newArray.push(splicedItem);
        }
    }
    return newArray;    
}

export const findWorkoutDaysInARow = (sortedWorkouts, dateStart = moment()) => {
    // Loop through the workouts and determine how many days in a row there has been a workout
    let currentDateInLoop = dateStart ? moment(dateStart) : moment();
    let count = 0;
    let lastDateInLoop = '';
    for (let i = 0; i < 2000; i++) {
        if (i === 0) lastDateInLoop = currentDateInLoop;

        const dateAgo = i===0 ? lastDateInLoop : lastDateInLoop.subtract('1', 'days'); // Only subtract a day if we are not dealing with today
        const foundWorkout = '';

        let found = false;
        sortedWorkouts.map((workout, workoutIndex) => {
            if (moment(workout.date).format('MM-DD-YYYY') == dateAgo.format('MM-DD-YYYY')) found = true;
        });

        if (found) {
            count++;
            continue;
        } else if (i === 0 && found === false) {
            // If we don't find a workout for today and the user worked out yesterday, we can still count it as a day in a row, so continue.
            continue;
        } else {
            break;
        }
    }

    return count; 
}

export const findWorkoutDaysPerWeek = (sortedWorkouts, weekStart = moment().startOf('week')) => {
    // Initialize variables
    let currentWeekStart = weekStart ? moment(weekStart) : moment().startOf('week');
    const endOfWeek = moment(currentWeekStart).endOf('week');
    let workoutDays = new Set(); // Use a set to ensure no duplicate days are counted

    // Loop through the workouts to count distinct workout days within the week
    sortedWorkouts.forEach((workout) => {
        const workoutDate = moment(workout.date);
        if (workoutDate.isBetween(currentWeekStart, endOfWeek, 'day', '[]')) {
            workoutDays.add(workoutDate.format('MM-DD-YYYY'));
        }
    });

    // Return the count of distinct workout days in the current week
    return workoutDays.size;
};

export const getWeekRange = (week = 0, format='DD/MM/YYYY') => {
    var weekStart = moment().add(week, 'weeks').startOf('week');
    var days = [];
    for (var i = 0; i < 7; i++) {
        days.push(weekStart.clone().add(i, 'day').format(format));
    }
    return days;
}

export const calculateExerciseDifficulty = ({
  weight = 100, // Weight in lbs
  reps = 10, // Reps per set
  sets = 3, // Number of sets
  bodyWeight = 175, // Body weight in lbs
  oneRepMax = null, // 1RM (null to estimate)
  exerciseMultiplier = 1.5, // Difficulty multiplier (e.g., squats)
  restTime = 90, // Actual rest time in seconds
  standardRest = 120, // Standard rest time in seconds
  tempo = 1.2 // Tempo adjustment factor
} = {}) => {
  // Calculate total volume
  const totalVolume = weight * reps * sets;

  // Estimate 1RM if not provided
  const estimatedOneRepMax = oneRepMax || weight * (1 + reps / 30);

  // Absolute intensity (% of 1RM)
  const absoluteIntensity = (weight / estimatedOneRepMax) * 100;

  // Relative intensity (weight relative to body weight)
  const relativeIntensity = bodyWeight ? (weight / bodyWeight) * 100 : null;

  // Rest factor
  const restFactor = standardRest / restTime;

  // Adjusted volume (total difficulty)
  const adjustedVolume = totalVolume * exerciseMultiplier * restFactor * tempo;

  // Determine intensity category based on absolute intensity
  let intensityCategory = 'Very Light';
  if (absoluteIntensity > 20 && absoluteIntensity <= 40) {
    intensityCategory = 'Light';
  } else if (absoluteIntensity > 40 && absoluteIntensity <= 60) {
    intensityCategory = 'Moderate';
  } else if (absoluteIntensity > 60 && absoluteIntensity <= 80) {
    intensityCategory = 'Hard';
  } else if (absoluteIntensity > 80) {
    intensityCategory = 'Very Hard';
  }

  // Return all calculated values as an object
  return {
    totalVolume,
    absoluteIntensity: absoluteIntensity.toFixed(2),
    relativeIntensity: relativeIntensity ? relativeIntensity.toFixed(2) : 'N/A',
    oneRepMax: estimatedOneRepMax.toFixed(2),
    adjustedVolume: adjustedVolume.toFixed(2),
    intensityCategory // Low, Medium, or High
  };
};

// utils/appendToPageHero.js

export function appendToPageHero(elementType, content, styles = {}) {
    // Locate the #page-hero element in the DOM
    const pageHero = document.getElementById('page-hero');

    if (pageHero) {
        // Create a new element of the specified type
        const newElement = document.createElement(elementType);

        // Set the content of the new element
        newElement.textContent = content;

        // Apply styles if provided
        Object.keys(styles).forEach((key) => {
            newElement.style[key] = styles[key];
        });

        // Append the new element to the #page-hero container
        pageHero.appendChild(newElement);
    } else {
        console.error('Element with ID #page-hero not found.');
    }
}

export function predictNextWorkout(workouts, currentDate, excludeGroups = []) {
    const muscleGroups = ["Chest", "Back", "Shoulders", "Legs", "Arms", "Core"];
    const muscleGroupUsage = {};

    // Parse the recent workouts
    workouts.forEach(workout => {
        const workoutDate = new Date(workout.date);
        const daysSinceWorkout = (new Date(currentDate) - workoutDate) / (1000 * 60 * 60 * 24);

        workout.exercises.forEach(exercise => {
            // Extract the muscle group from the workout name
            const group = muscleGroups.find(mg => workout.name.toLowerCase().includes(mg.toLowerCase())) || "Other";

            // Update muscle group usage with recency
            if (!muscleGroupUsage[group] || muscleGroupUsage[group] > daysSinceWorkout) {
                muscleGroupUsage[group] = daysSinceWorkout;
            }
        });
    });

    // Filter out excluded muscle groups
    const filteredMuscleGroups = muscleGroups.filter(group => !excludeGroups.includes(group));

    // Find muscle groups not recently worked on or least recently worked on
    const recommendedGroups = filteredMuscleGroups.filter(group => !muscleGroupUsage[group]);
    if (recommendedGroups.length === 0) {
        const sortedGroups = Object.entries(muscleGroupUsage)
            .filter(([group]) => filteredMuscleGroups.includes(group))
            .sort((a, b) => b[1] - a[1]);
        return sortedGroups[0][0]; // Recommend the least recently trained group
    }

    return recommendedGroups; // Recommend groups not worked on recently
}

export function getSetsTotals(setsObj) {
    if (!setsObj || !Array.isArray(setsObj)) return { totalWeight: 0, totalReps: 0, totalSets: 0 };

    return setsObj.reduce(
        (totals, set) => {
            const { weight = 0, reps = 0 } = set; // Default to 0 if properties are missing
            totals.totalWeight += weight * reps; // Multiply weight by reps for total weight
            totals.totalReps += reps;
            totals.totalSets += 1; // Count the set
            return totals;
        },
        { totalWeight: 0, totalReps: 0, totalSets: 0 } // Initial totals
    );
}

export function calculateCalories(workout, userWeightInLbs, output = 'kcal') {
  const MET_VALUES = {
    cardio: 4.0, // Example for Incline Walking
    weights: 6.0 // General weightlifting
  };

  let totalCalories = 0;

  workout.exercises.forEach((exercise) => {
    const { type, sets, duration } = exercise;
    const met = MET_VALUES[type] || 6.0; // Default MET for unknown types

    // Calculate duration in minutes
    let exerciseDurationInMinutes = 0;

    if (type === "cardio" && duration) {
      exerciseDurationInMinutes =
        duration.hours * 60 + duration.minutes + duration.seconds / 60;
    } else if (type === "weights") {
      // Estimate duration for weights based on reps (e.g., 2.5 seconds per rep)
      let totalReps = sets.reduce((sum, set) => sum + set.reps, 0);
      exerciseDurationInMinutes = (totalReps * 2.5) / 60;
    }

    // Calculate calories burned for this exercise
    const caloriesForExercise =
      met * userWeightInLbs * 0.000126 * exerciseDurationInMinutes;

    totalCalories += caloriesForExercise;
  });

  return (output === 'cal') ? Math.ceil(totalCalories * 1000) : totalCalories;
}
