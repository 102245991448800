import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';

const TopExercisesBarChart = ({ chartData }) => (
  <div style={{ width: "100%", backgroundColor: "white", borderRadius: "8px", padding: "0.5rem" }}>
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={chartData}
        layout="vertical"
        margin={{ top: 10, right: 10, left: 10, bottom: 10 }}
      >
        <YAxis 
          dataKey="name" 
          type="category" 
          width={100} 
          tick={{ fontSize: 12 }} // Smaller font for mobile readability
        />
        <XAxis 
          type="number" 
          tick={{ fontSize: 12 }} // Smaller font for mobile readability
        />
        <Tooltip 
          contentStyle={{ fontSize: "12px" }} // Adjust tooltip font size for smaller screens
        />
        <Bar dataKey="count" fill="#001f3f" barSize={20} /> {/* Reduced bar size */}
      </BarChart>
    </ResponsiveContainer>
  </div>
);

export default TopExercisesBarChart;
