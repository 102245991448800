import { React, useEffect, useState } from 'react';
import { Badge, Button, Dropdown, DropdownButton, Form, Image, InputGroup, Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './addExerciseModal.scss';
import styled from "styled-components";
import bbImg from '../../assets/images/barbell.png';
import { makeId, capitalizeFirstLetter, scrollTo, calculateExerciseDifficulty } from 'assets/utils';
import _ from 'underscore';
import axios from 'axios';
import { API_URL } from '../../assets/constants';
import Select from 'react-select';

// IMAGES
// import hexImg from '../../assets/images/hexagon.png';

// COMPONENTS
import DurationPicker from '../durationPicker/DurationPicker';
import ExerciseHistoryChart from '../exerciseHistoryChart/ExerciseHistoryChart';
import WorkoutLog from '../workoutLog/WorkoutLog';
import MaxWeightBanner from 'components/maxWeightBanner/MaxWeightBanner';

// STYLED COMPONENTS
const SpaceAfter = styled.div`
  margin-bottom: 1rem;
`;

const getDefaultSet = () => {
  return {
    id: makeId(26),
    weight: 0,
    reps: 0,
    bbClickCount: 0,
    warmup: 0,
  }
};

function AddExerciseModal({ exercise, showModal, closeModal, saveExercise }) {
  // STATE PARAMS
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [formData, setFormData] = useState({
    id: exercise.id || makeId(26),
    name: exercise.name || '',
    exerciseId: exercise.exerciseId || '',
    sets: exercise.sets || [getDefaultSet()],
    duration: exercise.duration || {hours: 0, minutes: 0, seconds: 0},
  });
  const [selectedExercise, setSelectedExercise] = useState(exercise || {});
  const [savedLabel, setSavedLabel] = useState(false);
  const [allExercises, setAllExercises] = useState([]);
  const [colorFlash, setColorFlash] = useState(false);
  const [weights, setWeights] = useState([
    {
      weight: 45,
      clicks: 0, 
    },
    {
      weight: 35,
      clicks: 0, 
    },
    {
      weight: 25,
      clicks: 0, 
    },
    {
      weight: 10,
      clicks: 0, 
    },
    {
      weight: 5,
      clicks: 0, 
    },
    {
      weight: 2.5,
      clicks: 0, 
    },                    
  ]);
  const [exerciseType, setExerciseType] = useState('');
  const [selectedUnit, setSelectedUnit] = useState('minutes');
  const [exerciseHistory, setExerciseHistory] = useState({});
  const [refreshWorkoutLog, setRefreshWorkoutLog] = useState(false);

  useEffect(() => {
    refreshExercises(exercises => {
      const exerciseObj = _.findWhere(exercises, {_id: exercise.exerciseId});
      if (exerciseObj) setExerciseType(exerciseObj.type);
    });
  }, [selectedExercise]);

  // Toggle warmup flag for a specific set
  const toggleWarmup = (setId, warmupFlag) => {
    if (typeof warmupFlag == 'undefined') warmupFlag = 0;
    updateSet(setId, {
      warmup: +!warmupFlag,
    });
  };

  const refreshExercises = callback => {
    async function getExercises() {
     const response = await axios.get(API_URL + `/api/exercises`);

     if (response.status !== 200) {
       const message = `An error occurred: ${response.statusText}`;
       window.alert(message);
       return;
     }

     return response.data;
    }
    getExercises()
      .then(exercises => {
        setExerciseType(_.findWhere(exercises, {_id: exercise.exerciseId}));
        setAllExercises(exercises)

        if (typeof callback == 'function') callback(exercises);
      });
  }

  const updateSet = (id, setValueObj) => {    
    let index = formData.sets.findIndex(x => x.id === id);
    /* This line is only necessary if your element's ID 
       isn't its position/index in the array or related to it.
       In the case that it is, use the ID as the index, or run the function
       (binary/hash) that relates the ID to that position/index to find the 
       index.
    */
    if (index !== -1) {
      let temporaryarray = formData.sets.slice();

      const nA = {...temporaryarray[index], ...setValueObj};
      temporaryarray[index] = nA;

      setFormData({...formData, sets: temporaryarray});
    } else {
      console.log('no match');
    }
  }

  const handleInputChange = (e, setId = null) => {
    const fieldName = e.target.name || '',
          val = e.target.value || '';
    
    if (!fieldName || !val) return;

    var obj = {};
    obj[fieldName] = val;

    if (setId) {
      // We are updating something on a set object
      updateSet(setId, obj);
    } else {
      // formData
      setFormData({...formData, [e.target.name]: e.target.value});
    }
  }

  const addWeight = (addedWeight, bbClick = false, setId) => {
    if (!setId) {
      console.error('No setId in addWeight()');
      return;
    }

    const setObj = _.findWhere(formData.sets, {id: setId});

    if (setObj.bbClickCount === 1 && bbClick === true) {
      return;
    } else {
      updateSet(setId, {
        'weight': parseFloat(setObj.weight + addedWeight), 
        'bbClickCount': ((setObj.bbClickCount === 1 || (setObj.bbClickCount === 0 && bbClick === true)) ? 1 : 0)
      });    
    }
  }

  const subtractWeight = (subtractedWeight, bbClick = false, setId) => {
    if (!setId) {
      console.error('No setId in addWeight()');
      return;
    }

    const setObj = _.findWhere(formData.sets, {id: setId});

    if (setObj.bbClickCount > 0 && bbClick === true) {
      return;
    }

    // setFormData({...formData, weight: parseInt(formData.weight - subtractedWeight)});

    updateSet(setId, {
      'weight': parseInt(setObj.weight - subtractedWeight), 
      'bbClickCount': (setObj.bbClickCount === 0 && bbClick === true ? 1 : 0)
    });
  }

  const resetWeight = setId => {
    updateSet(setId, {'weight': 0, 'bbClickCount': 0});
  }

  const addReps = setId => {
    const setObj = _.findWhere(formData.sets, {id: setId});
    const newReps = parseInt(setObj.reps + 1);

    updateSet(setId, {'reps': newReps});
  }

  const subtractReps = setId => {
    const setObj = _.findWhere(formData.sets, {id: setId});
    const newReps = setObj.reps === 0 ? 0 : parseInt(setObj.reps - 1);

    updateSet(setId, {'reps': newReps});
  }

  const addSet = () => {
    const nS = getDefaultSet();
    const lastSetIndex = formData.sets.length - 1;
    nS.weight = formData.sets[lastSetIndex].weight;
    // nS.reps = formData.sets[lastSetIndex].reps;
    nS.bbClickCount = formData.sets[lastSetIndex].bbClickCount;

    const newSets = [...formData.sets, nS];

    setColorFlash(true);
    setFormData({...formData, sets: newSets});

    setTimeout(() => {
      setColorFlash(false);
    }, 500);
  }

  const removeSet = setIndex => {
    if (window.confirm("Are you sure you want to delete this set?")) {
      var array = [...formData.sets];
      array.splice(setIndex, 1);
      setFormData({...formData, sets: array});
    }
  }

  const saveExerciseCallback = exercise => {
    setSavedLabel(true);
    setRefreshWorkoutLog(true);

    setTimeout(() => {
      setSavedLabel(false);
      setRefreshWorkoutLog(false);
    }, 2000);
  }

  const handleExerciseSelect = selectedOption => {
    const selectedOptionText = selectedOption.label;
    setFormData({...formData, exerciseId: selectedOption.value, name: selectedOptionText});
    setExerciseType(_.findWhere(allExercises, {_id: selectedOption.value}).type);
    setSelectedExercise(_.findWhere(allExercises, {_id: selectedOption.value}));
  }

  const handleDurationChange = e => {
    const durationInput = e.target.value;
    setFormData({...formData, duration: {time: durationInput, unit: selectedUnit}})
  }

  const handleDurationSelect = (key, e) => {
    const unit = e.target.innerHTML.toLowerCase();
    setFormData({...formData, duration: {time: formData.duration.time, unit: unit}});
    setSelectedUnit(unit);
  }

  const options = allExercises.map((ex) => ({
    value: ex._id,
    label: ex.name,
    exercise: ex, // Include the full exercise object for further use if needed
  }));

  return (
    <Modal show={showModal} onHide={() => closeModal()} size="lg" id="add-exercise-modal" animation={false} backdrop="static" keyboard={false}>
      <Modal.Body className={colorFlash ? 'color-flash-activate' : ''}>
        {colorFlash && <div id="color-flash-box"></div>}

{/*        <Form.Select aria-label="Exercise select" value={formData.exerciseId} style={{marginBottom: '2rem'}} onChange={handleExerciseSelect}>
          <option></option>
          {
            allExercises.length > 0 &&
            allExercises.map((ex, i) => (
              <option key={i} value={ex._id} exercise={JSON.stringify(ex)}>{ex.name}</option>
            ))
          }
        </Form.Select>  */}

        <Select
          options={options}
          value={options.find((option) => option.value === formData.exerciseId) || null}
          onChange={handleExerciseSelect}
          placeholder="Select an exercise"
          isClearable
          styles={{
            container: (provided) => ({
              ...provided,
              marginBottom: '2rem',
              fontSize: '1.5rem',
            }),
          }}
        />

        {_.findWhere(allExercises, {_id: formData.exerciseId}) && _.findWhere(allExercises, {_id: formData.exerciseId}).type == 'cardio' && <DurationPicker data={formData.duration} getData={duration => setFormData({...formData, duration: duration})} />}
        
        {
          formData.exerciseId !== '' && allExercises.length > 0 && _.findWhere(allExercises, {_id: formData.exerciseId}).type && _.findWhere(allExercises, {_id: formData.exerciseId}).type == 'weights' &&
          <div id="sets-section"> 
          {
            formData.sets.map((set, setIndex) => (
              <div key={setIndex} id={'set-' + setIndex}>
                <section>
                  <SpaceAfter>
                    <h4 id="sets">SET <span style={{color: '#FF4136'}}>{setIndex + 1}</span>/{formData.sets.length}</h4>
                  </SpaceAfter>
                </section>

                <section style={{marginBottom: '2rem'}}>
                  <Form.Group>
                    <Form.Control id="weight" type="text" placeholder="0" name="weight" onChange={e => handleInputChange(e, set.id)} value={set.weight} />
                  </Form.Group>
                  <Image onClick={() => addWeight(45, true, set.id)} id="bb-img" src={bbImg} className={set.bbClickCount === 1 ? 'bb-selected' : ''} fluid />
                </section>

                {/* BARBELLIMAGE WITH 45 LBS? */}
                <section className="weights-section">
                  {
                    weights.map((obj, wI) => (
                    <div key={wI} className={"hex-img-container " + "lbs-" + (obj.weight != 2.5 ? obj.weight : '2half')} onClick={() => addWeight(obj.weight, false, set.id)}>
                      <span>{obj.weight}</span>
                    </div>
                    ))
                  }         

                  <div id="weight-buttons-section">
                    <h6>
                      <Badge
                        set={set.warmup}
                        bg={!set.warmup || set.warmup === 0 ? 'secondary' : 'info'}
                        className="warmup-badge"
                        onClick={() => toggleWarmup(set.id, set.warmup)}
                      >
                        Warmup
                      </Badge>
                    </h6>
                    <h6><Badge bg="secondary" onClick={() => resetWeight(set.id)}>Reset</Badge></h6>
                    {formData.sets.length > 1 && <h6><Badge bg="danger" onClick={() => removeSet(setIndex)}>Remove Set</Badge></h6>}
                  </div>
                </section>

                <section id="reps-section">
                  <SpaceAfter>
                    <h4 id="reps-header">REPS</h4>
                  </SpaceAfter>

                  <Form.Group style={{position: 'relative'}}>
                    <InputGroup>
                      <Button variant="outline-secondary" id="button-addon1" onClick={() => subtractReps(set.id)}>-</Button>
                      <Form.Control id="reps" type="number" placeholder="0" name="reps" onChange={e => handleInputChange(e, set.id)} value={set.reps} />
                      <Button variant="outline-secondary" id="button-addon2" onClick={() => addReps(set.id)}>+</Button>
                    </InputGroup>
                  </Form.Group>
                </section>

                {/* Anything else? */}
              </div>
            ))
          }
          </div>
        }

        {
          (selectedExercise.exerciseId || selectedExercise._id || selectedExercise.id) && selectedExercise.type == 'cardio' &&
          <section id="exercise-history-chart-section">
            <h1 style={{margin: '1rem 0', textAlign: 'center', color: 'white', fontSize: '2rem'}}>Historical</h1>
            <ExerciseHistoryChart exercise={selectedExercise} />
          </section>
        }

        {
          (selectedExercise.exerciseId || selectedExercise._id || selectedExercise.id) &&
          <section id="workout-log">
            <MaxWeightBanner exerciseId={selectedExercise.exerciseId || selectedExercise._id || selectedExercise.id} refresh={refreshWorkoutLog} />
            <WorkoutLog refresh={refreshWorkoutLog} exercise={selectedExercise} exerciseId={selectedExercise.exerciseId || selectedExercise._id || selectedExercise.id} />
          </section>
        }        
      </Modal.Body>

      <Modal.Footer style={{backgroundColor: '#eeeeee'}}>
        {savedLabel && <span>Saved...</span>}
        <Button variant="success" onClick={() => saveExercise(formData, saveExerciseCallback)}>Save</Button>
        <Button variant="secondary" onClick={() => addSet()}>+ Set</Button>
        <Button variant="secondary" onClick={() => closeModal()}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddExerciseModal;
