import React, { useState, useEffect } from 'react';
import './monthlyWorkoutMeter.scss';

const MonthlyWorkoutMeter = ({ workoutData }) => {
  const [progress, setProgress] = useState(0);
  const [uniqueWorkoutDays, setUniqueWorkoutDays] = useState(0);
  const [totalDays, setTotalDays] = useState(0);

  useEffect(() => {
    if (workoutData && workoutData.length > 0) {
      // Derive current month and year from the first workout (or use today's date if needed)
      const firstWorkoutDate = new Date(workoutData[0].date);
      const currentMonth = firstWorkoutDate.getMonth();
      const currentYear = firstWorkoutDate.getFullYear();

      // Filter workouts for the current month and year
      const workoutsInMonth = workoutData.filter((workout) => {
        const workoutDate = new Date(workout.date);
        return (
          workoutDate.getMonth() === currentMonth &&
          workoutDate.getFullYear() === currentYear
        );
      });

      // Get unique workout days for the current month
      const uniqueDays = [
        ...new Set(
          workoutsInMonth.map((workout) => new Date(workout.date).getDate())
        ),
      ];

      // Calculate total days in the current month
      const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();

      // Update state
      setUniqueWorkoutDays(uniqueDays.length);
      setTotalDays(daysInMonth);
      setProgress((uniqueDays.length / daysInMonth) * 100);
    } else {
      // Reset values if no data
      setUniqueWorkoutDays(0);
      setTotalDays(0);
      setProgress(0);
    }
  }, [workoutData]); // Run whenever workoutData changes

  return (
    <div id="monthly-workout-meter">
      <h3>Monthly Workout Progress</h3>
      <div className="progress">
        <div
          className="progress-bar"
          style={{ width: `${progress}%` }}
        ></div>
      </div>
      <p>
        {uniqueWorkoutDays}/{totalDays} Days Worked Out ({!isNaN(Math.round((uniqueWorkoutDays / totalDays) * 100)) ? Math.round((uniqueWorkoutDays / totalDays) * 100) : 0}%)
      </p>
    </div>
  );
};

export default MonthlyWorkoutMeter;
