import React, { useEffect, useRef } from "react";
import Vizzu from "vizzu";

const WorkoutVisualization = ({
  data,
  showTitle = true,
  customWidth = '100%',
  metric = 'reps',
  timeRange = 'all' // New prop to select between 'monthly', 'quarterly', 'yearly', and 'all'
}) => {
  const chartRef = useRef(null);
  const vizzuInstance = useRef(null);

  // Function to format dates
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options); // Use locale for better format
  };

  // Helper functions to filter data based on the time range
  const isInTimeRange = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();

    switch (timeRange) {
      case 'monthly': {
        return date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear();
      }
      case 'quarterly': {
        const currentQuarter = Math.floor(now.getMonth() / 3); // 0-based index for quarter
        const dateQuarter = Math.floor(date.getMonth() / 3);
        return dateQuarter === currentQuarter && date.getFullYear() === now.getFullYear();
      }
      case 'biyearly': {
        const currentHalf = Math.floor(now.getMonth() / 6); // 0 for Jan-Jun, 1 for Jul-Dec
        const dateHalf = Math.floor(date.getMonth() / 6);
        return dateHalf === currentHalf && date.getFullYear() === now.getFullYear();
      }      
      case 'yearly': {
        return date.getFullYear() === now.getFullYear();
      }
      case 'all':
      default: {
        return true; // Show all data
      }
    }
  };

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.innerHTML = ""; // Clear the container
    }

    // Initialize Vizzu
    vizzuInstance.current = new Vizzu(chartRef.current);

    // Filter data based on the selected time range
    const filteredData = data.filter((workout) => isInTimeRange(workout.workoutDate));

    // Prepare the data for Vizzu
    const preparedData = {
      series: [
        { name: "Date", type: "dimension", values: [] },
        { name: "Set", type: "dimension", values: [] },
        { name: metric.charAt(0).toUpperCase() + metric.slice(1), type: "measure", values: [] },
      ],
    };

    filteredData.forEach((workout) => {
      workout.sets.forEach((set, index) => {
        preparedData.series[0].values.push(formatDate(workout.workoutDate)); // Format dates
        preparedData.series[1].values.push(`Set ${index + 1}`);
        preparedData.series[2].values.push(set[metric]); // Use dynamic metric (reps or weight)
      });
    });

    // Configure and render the Vizzu chart
    vizzuInstance.current
      .animate({ data: preparedData })
      .then(() =>
        vizzuInstance.current.animate({
          config: Vizzu.presets.stackedColumn({
            x: "Date",
            y: metric.charAt(0).toUpperCase() + metric.slice(1),
            stackedBy: "Set",
            ...(showTitle && {
              title: `Stacked Column Chart: Total ${metric.charAt(0).toUpperCase() + metric.slice(1)} per Day (${timeRange.charAt(0).toUpperCase() + timeRange.slice(1)})`,
            }),
          }),
          style: {
            plot: {
              xAxis: {
                label: {
                  angle: 45,
                  fontSize: "10px",
                  color: "#333",
                },
              },
            },
          },
        })
      );

    // Cleanup logic
    return () => {
      if (vizzuInstance.current) {
        vizzuInstance.current = null; // Reset the Vizzu instance
      }
    };
  }, [data, showTitle, metric, timeRange]); // Re-run when props change

  return (
    <div>
      {showTitle && <h3>Workout Visualization</h3>}
      <div ref={chartRef} style={{ width: customWidth, height: "500px" }}></div>
    </div>
  );
};

export default WorkoutVisualization;
