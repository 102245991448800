import { React, useEffect, useState } from 'react';
import { Col, Container, Row, Modal, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/workoutTemplates.scss';
import { useNavigate } from 'react-router-dom';
import { workoutTemplatesAPI } from 'apiBank';

import PageHero from 'components/pageHero/PageHero';
import AddWorkoutTemplateForm from 'components/addWorkoutTemplateForm/AddWorkoutTemplateForm';
import WorkoutTemplatePanel from 'components/workoutTemplatePanel/WorkoutTemplatePanel';
import WorkoutTemplateDetailsModal from 'components/workoutTemplateDetailsModal/WorkoutTemplateDetailsModal';

function WorkoutTemplates() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [templates, setTemplates] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    refreshWorkoutTemplates();
  }, []);

  const refreshWorkoutTemplates = (callback) => {
    workoutTemplatesAPI.getWorkoutTemplatesByUserId(user._id)
      .then((templates) => {
        console.log('templates', templates);
        setTemplates(templates);
        if (typeof callback === 'function') callback(templates);
      });
  };

  const handleSubmitCallback = (response) => {
    refreshWorkoutTemplates();
  };

  const handlePanelClick = (template) => {
    console.log("Clicked template", template);
    setSelectedTemplate(template);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
    setSelectedTemplate(null);
  };

  const handleSaveChanges = (updatedTemplate) => {
    workoutTemplatesAPI.updateWorkoutTemplate(updatedTemplate._id, updatedTemplate)
      .then(() => {
        refreshWorkoutTemplates();
        handleModalClose();
      })
      .catch((error) => {
        console.error('Error updating template:', error);
      });
  };

  const handleOnCreateWorkout = response => {
    if (response && response._id) {
      const newWorkoutId = response._id;
      navigate('/workout-details', {
        state: {
          workoutId: newWorkoutId,
        },
      });      
    }
  }

  return (
    <div id="workout-templates-page">
      <PageHero
        copy="Workout Templates"
        cssOptions={{
          bgImage: localStorage.getItem('bgColor'),
        }}
      />

      <Container>
        <Row>
          <Col>
            <AddWorkoutTemplateForm handleSubmitCallback={handleSubmitCallback} />

            <section id="workout-template-list-section">
              <h3>Templates</h3>
              <hr />
              {templates ? (
                templates.map((template) => (
                  <WorkoutTemplatePanel
                    key={template.name}
                    template={template}
                    handlePanelClick={handlePanelClick}
                  />
                ))
              ) : (
                <p>No templates</p>
              )}
            </section>
          </Col>
        </Row>
      </Container>

      <WorkoutTemplateDetailsModal
        show={showModal}
        onClose={handleModalClose}
        template={selectedTemplate}
        onSave={handleSaveChanges}
        onCreateWorkout={handleOnCreateWorkout}
        onDelete={refreshWorkoutTemplates}
      />
    </div>
  );
}

export default WorkoutTemplates;
