import React, { useState, useEffect } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import Select from 'react-select';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

import { API_URL } from 'assets/constants';
import { workoutTemplatesAPI } from 'apiBank';

const AddWorkoutTemplateForm = ({ handleSubmitCallback }) => {
  const [formData, setFormData] = useState({
    name: '',
    exercises: []
  });
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [exercisesOptions, setExercisesOptions] = useState([]);

  // Fetch Exercises from API
  const refreshExercises = (callback) => {
    async function getExercises() {
      const response = await axios.get(`${API_URL}/api/exercises`);

      if (response.status !== 200) {
        const message = `An error occurred: ${response.statusText}`;
        window.alert(message);
        return [];
      }

      return response.data;
    }

    getExercises().then((exercises) => {
      let typeArr = {};

      exercises.forEach((exercise) => {
        if (!typeArr[exercise._id]) typeArr[exercise._id] = [];
        if (exercise._id) typeArr[exercise._id].push(exercise);
      });

      const formattedExercises = exercises.map((exercise) => ({
        value: exercise._id,
        label: exercise.name
      }));

      setExercisesOptions(formattedExercises);

      if (typeof callback === 'function') callback();
    });
  };

  useEffect(() => {
    refreshExercises();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (selectedOptions) => {
    setFormData({ ...formData, exercises: selectedOptions || [] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Extract only the 'value' (exerciseId) from each selected option
    const exerciseIds = formData.exercises.map((exercise) => exercise.value);

    console.log('Form Data:', {
      ...formData,
      exercises: exerciseIds, // Transformed exercises list
    });

    // INSERT THE WORKOUT TEMPLATE
    workoutTemplatesAPI
      .insertWorkoutTemplate({
        ...formData,
        exercises: exerciseIds, // Send only exercise IDs
        user_id: user._id,
        date_created: new Date().toISOString(),
      })
      .then((response) => {
        console.log(response);

        handleSubmitCallback(response);

        // Reset the form after successful insertion
        setFormData({
          name: '',
          exercises: [],
        });
      })
      .catch((error) => {
        console.error('Error inserting workout template:', error);
        alert(error.response.data.error || error);
      });
  };

  const refreshWorkoutTemplates = () => {
    console.log('In refreshWorkoutTemplates...');
  };

  return (
    <Container className="mt-4">
      <h3>Add Workout Template</h3>
      <Form onSubmit={handleSubmit}>
        {/* Name Field */}
        <Form.Group controlId="formName">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter workout template name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
          />
        </Form.Group>

        {/* Exercises Multi-select */}
        <Form.Group controlId="formExercises" className="mt-3">
          <Form.Label>Exercises</Form.Label>
          <Select
            options={exercisesOptions}
            isMulti
            placeholder="Select exercises"
            value={formData.exercises}
            onChange={handleSelectChange}
          />
        </Form.Group>

        {/* Submit Button */}
        <Button variant="primary" type="submit" className="mt-3">
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default AddWorkoutTemplateForm;
