import { React, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/workouts.scss';
import { Button, Card, Col, Container, Image, Row, Table, Fade } from 'react-bootstrap';
import { scrollTo } from '../assets/utils';
import axios from 'axios';
import moment from 'moment';
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { API_URL } from '../assets/constants';
import { workoutsAPI } from 'apiBank';

/* COMPONENTS */
import NewWorkoutForm from '../components/newWorkoutForm/NewWorkoutForm';
import PageHero from '../components/pageHero/PageHero';
import WorkoutPanel from '../components/workoutPanel/WorkoutPanel';
import MonthlyWorkoutMeter from 'components/monthlyWorkoutMeter/MonthlyWorkoutMeter';
import MonthlyEfficiencyMeter from 'components/monthlyEfficiencyMeter/MonthlyEfficiencyMeter';

const SpaceAfter = styled.div`
  margin-bottom: 1rem;
`;

function Workouts() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [workouts, setWorkouts] = useState([]);
  const [showAddWorkout, setShowAddWorkout] = useState(false);
  const [calDate, setCalDate] = useState(new Date());
  const [calDateSelected, setCalDateSelectd] = useState('');
  const [showAllWorkouts, setShowAllWorkouts] = useState(false);
  const [workoutsForHighlights, setWorkoutsForHighlights] = useState([]);
  const [skipScroll, setSkipScroll] = useState(false);
  const [activeMonth, setActiveMonth] = useState((new Date()).getMonth() + 1);

  const navigate = useNavigate();

  useEffect(() => {
    if (skipScroll === false) scrollTo('workouts-page');
    handleCallDateClick(new Date());
    setWorkoutsByMonth((new Date()).getMonth() + 1);
  }, []);

  const handleMonthChange = ({ activeStartDate }) => {
    const monthNo = (new Date(activeStartDate)).getMonth() + 1;
    const fullYear = (new Date(activeStartDate)).getFullYear();

    setActiveMonth(monthNo);
    setWorkoutsByMonth(monthNo);
  };

  const setWorkoutsByMonth = monthNo => {
    (async () => {
      try {
        const workoutsByMonth = await workoutsAPI.getWorkoutsByMonth(user._id, monthNo);
        setWorkoutsForHighlights(workoutsByMonth);
      } catch (error) {
        console.error('Error fetching workouts:', error);
      }
    })();    
  }

  const refreshWorkouts = () => {
     async function getWorkouts() {
       const response = await axios.get(API_URL + `/api/workouts/list/${user._id}`);

       if (response.status !== 200) {
         const message = `An error occurred: ${response.statusText}`;
         window.alert(message);
         return;
       }
   
       return response.data;       
     }
     getWorkouts().then(data => {
      setWorkouts(data.sort((a,b) => new Date(b.date) - new Date(a.date))); // sort by date property
     })      
  }

  const getWorkouts = callback => {
     async function getWorkouts() {
       const response = await axios.get(API_URL + `/api/workouts/list/${user._id}`);

       if (response.status !== 200) {
         const message = `An error occurred: ${response.statusText}`;
         window.alert(message);
         return;
       }
   
       return response.data;       
     }
     getWorkouts().then(data => {
      if (typeof callback == 'function') callback(data.sort((a,b) => new Date(b.date) - new Date(a.date))); // sort by date property
     })      
  }

  const handleShowAddWorkout = () => {
    setShowAddWorkout(!showAddWorkout); 
  }

  const getWorkoutsByDate = (dateClicked, callback) => {
    const dateFormatted = moment(dateClicked).format('YYYY-MM-DD');

    // Set state
    setCalDate(dateClicked);
    setCalDateSelectd(dateFormatted);

     async function gwbd() {
       const response = await axios.post(API_URL + `/api/workouts/date/${user._id}`, {date: dateFormatted});

       if (response.status !== 200) {
         const message = `An error occurred: ${response.statusText}`;
         window.alert(message);
         return;
       }
   
       return response.data;       
     }
     gwbd()
      .then(data => {
        if (typeof(callback) == 'function') callback(data);
      });
   }

  const handleCallDateClick = dateClicked => {
    const dateFormatted = moment(dateClicked).format('YYYY-MM-DD');

    // Set state
    setCalDate(dateClicked);
    setCalDateSelectd(dateFormatted);

     async function apiGetWorkoutsByDate() {
       const response = await axios.post(API_URL + `/api/workouts/date/${user._id}`, {date: dateFormatted});

       if (response.status !== 200) {
         const message = `An error occurred: ${response.statusText}`;
         window.alert(message);
         return;
       }
   
       return response.data;       
     }
     apiGetWorkoutsByDate().then(data => {
      setShowAllWorkouts(false);
      setWorkouts(data);
     })      
  }

  const handleShowAllWorkouts = () => {   
    const flag = !showAllWorkouts;
    setSkipScroll(true);
    setShowAllWorkouts(flag);
    if (flag === true) {
      refreshWorkouts();
    } else {
      handleCallDateClick()
    }
  }

  const tileClassName = ({ date, view }) => {
    if (!workoutsForHighlights || !Array.isArray(workoutsForHighlights) || workoutsForHighlights.length === 0) return;

    if (view === 'month') {
      let highlight = false;

      workoutsForHighlights.forEach(workout => {
        if (moment(workout.date).format("MM/DD/YYYY") === moment(date).format("MM/DD/YYYY")) {
          highlight = true;
        }
      });

      return highlight ? 'highlight' : '';
    }
  };

  return (
    <div id="workouts-page">
      <PageHero copy="Workouts" cssOptions={{
        bgImage: localStorage.getItem('bgColor')
      }} />          

      <section id="workout-panel-section">
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <div style={{marginBottom: '2rem'}}>
                {workoutsForHighlights && <MonthlyWorkoutMeter workoutData={workoutsForHighlights} />}
                {(activeMonth == (new Date()).getMonth() + 1) && workoutsForHighlights && <MonthlyEfficiencyMeter workoutData={workoutsForHighlights} />}                
              </div>

              <div style={{margin: '0 0 2rem 0'}}>
                <Calendar onChange={handleCallDateClick} value={calDate} tileClassName={tileClassName} onActiveStartDateChange={handleMonthChange} />
              </div>

              <div id="button-container">
                <Button variant="success" className={showAddWorkout ? 'btn-alt' : ''} onClick={handleShowAddWorkout}>{showAddWorkout ? '-' : '+'} Workout</Button>
                <Button variant="success" className={showAllWorkouts ? 'btn-alt' : ''} onClick={handleShowAllWorkouts}>{showAllWorkouts ? '-' : '+'} All Workouts</Button>
              </div>

              <NewWorkoutForm show={showAddWorkout} refreshWorkouts={handleCallDateClick} />

              {
                workouts.length > 0 ?
                workouts.map((workout, i) => (
                  <SpaceAfter key={i}>
                    <WorkoutPanel workout={workout} />
                  </SpaceAfter>
                ))
                : <h3>No Workouts</h3>
              }
            </Col>
          </Row>
        </Container>
      </section>

    </div>
  );
}

export default Workouts;
