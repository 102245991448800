import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './workoutTemplatePanel.scss';

/* COMPONENTS */
import HealthImg from 'assets/images/icons/health_icon.svg';
import SwimmingImg from 'assets/images/swimming-pool.jpg'; // Local image for swimming background
import BlueprintImg from 'assets/images/blueprint_bg.jpg'; // Local image for other templates
import StarRating from 'components/starRating/StarRating';

function WorkoutTemplatePanel({ template, styles, handlePanelClick }) {
  const navigate = useNavigate();

  const goToDetails = (template) => {
    navigate('/template-details', {
      state: {
        templateId: template._id,
      },
    });
  };

  // Determine if the template name is 'swimming' (case-insensitive)
  const isSwimming = template.name.toLowerCase() === 'swimming';

  // Set the background image based on the template name
  const backgroundImage = `url(${BlueprintImg})`;

  // Combine the passed styles with the background image style
  const combinedStyles = {
    ...styles,
    backgroundImage,
    backgroundSize: 'cover', // Ensure the background image covers the container
    backgroundPosition: 'center', // Center the background image
  };

  return (
    <>
      <div
        className="workout-panel"
        style={combinedStyles}
        onClick={() => handlePanelClick(template)}
      >
        <h3>{template.name}</h3>
        {template.hasHealthIcon && (
          <Image className="health-icon" fluid src={HealthImg} />
        )}
        <StarRating rating={template.rating} locked={true} />
      </div>
      <small>{template.lastModified}</small>
    </>
  );
}

export default WorkoutTemplatePanel;
