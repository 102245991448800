import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './durationPicker.scss';
import { Form, Table } from 'react-bootstrap';

function DurationPicker({ data, getData }) {
  const [duration, setDuration] = useState(data || { hours: 0, minutes: 0, seconds: 0 });

  const handleDurationChange = (e) => {
    const newValue = parseInt(e.target.value || 0);
    const updatedDuration = { ...duration, [e.target.name]: newValue };
    setDuration(updatedDuration);
    getData(updatedDuration);
  };

  const formatValue = (value) => value.toString().padStart(2, '0');

  return (
    <div id="duration-picker">
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Hours</th>
            <th>Minutes</th>
            <th>Seconds</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Form.Control
                type="number"
                min="0"
                placeholder="hours"
                name="hours"
                value={formatValue(duration.hours)}
                onChange={handleDurationChange}
              />
            </td>
            <td>
              <Form.Control
                type="number"
                min="0"
                placeholder="minutes"
                name="minutes"
                value={formatValue(duration.minutes)}
                onChange={handleDurationChange}
              />
            </td>
            <td>
              <Form.Control
                type="number"
                min="0"
                placeholder="seconds"
                name="seconds"
                value={formatValue(duration.seconds)}
                onChange={handleDurationChange}
              />
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default DurationPicker;
